<template>
  <b-modal
    id="modal-question"
    v-model="show"
    title="Thêm bộ câu hỏi"
    size="lg"
    centered
    cancel-title="Hủy"
    ok-title="Lưu"
    ok-variant="success"
    @hidden="resetModal"
    @ok="saveQuestions"
  >
    <b-row>
      <b-col cols="6">
        <basic-select
          label="Bộ câu hỏi"
          placeholder="--- Chọn bộ câu hỏi ---"
          name="type"
          :solid="false"
          :allowEmpty="true"
          value-label="text"
          track-by="value"
          :options="questionGroups"
          :value.sync="questionGroup"
          :disabled="loading"
          @update:value="selectQuesitonGroup"
        />
      </b-col>
    </b-row>
    <div class="wrap-table">
      <b-overlay :show="loading">
        <TableQuestion
          :headers="headers"
          :data="questions"
          @select="select"
          @remove="removeSelect"
        />
      </b-overlay>
    </div>
  </b-modal>
</template>
<script>
import { SET_MODAL } from '@/core/services/store/context.module';
import { ADD_QUESTION_SURVEY } from '@/core/services/store/survey.module';
import { createNamespacedHelpers } from 'vuex';
import differenceBy from 'lodash/differenceBy';
import { MODAL_STATUS } from '@/core/plugins/constants';
const { mapMutations, mapState } = createNamespacedHelpers('survey');
export default {
  name: 'ModalQuestion',
  components: {
    TableQuestion: () => import('./TableQuestion.vue'),
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      questions: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      headers: [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'select',
          width: '5%',
        },
        {
          text: 'Câu hỏi',
          align: 'start',
          sortable: false,
          value: 'question',
        },
      ],
      questionGroups: [],
      questionGroup: null,
      loading: false,
      selectedQuestions: [],
      deletedQuestions: [],
    };
  },
  computed: {
    ...mapState(['sections']),
    show: {
      get() {
        return this.$store.state.context.modalShow;
      },
      set(val) {
        this.$store.commit(`context/${SET_MODAL}`, val);
      },
    },
  },
  created() {
    this.getQuestionGroups();
    this.getQuestionsById();
  },
  methods: {
    ...mapMutations({ ADD_QUESTION_SURVEY }),
    resetModal() {
      this.$store.commit(`context/${SET_MODAL}`, false);
      this.$emit('reset');
    },
    async getQuestionGroups() {
      try {
        const { meta, data } = await this.$api.get(
          '/SurveyQuestionGroup/SelectableListItem',
        );
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: meta.message,
          });
        }
        this.questionGroups = data;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      }
    },
    async getQuestionsById() {
      try {
        this.loading = true;
        const params = {
          surveyGroupQuestionId: this.questionGroup?.value,
        };
        const { meta, data } = await this.$api.get('/SurveyQuestion', {
          params,
        });
        if (!meta.success) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: meta.message,
          });
        }
        this.questions = data.map((el) => ({ ...el, checked: true }));
        const section = this.sections.find((el) => el.id === this.id);
        if (section.questions?.length) {
          const selectedQuestions = section.questions.map((el) => el.id);
          this.questions = this.questions.filter(
            (el) => !selectedQuestions.includes(el.id),
          );
          return (this.selectedQuestions = [...this.questions]);
        }
        return (this.selectedQuestions = [...this.questions]);
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },
    select(params) {
      const relatedQuestion = this.questions.find(
        (el) => params.isRelatedQuestions && params.mappedQuestionId === el.id,
      );
      if (relatedQuestion && !relatedQuestion.checked) {
        relatedQuestion.checked = true;
        this.selectedQuestions.push(relatedQuestion);
      }

      this.selectedQuestions.push(params);
    },
    removeSelect(params) {
      const relatedQuestion = this.questions.find(
        (el) => el.mappedQuestionId === params.id,
      );
      if (relatedQuestion && relatedQuestion.checked) {
        return this.showPopupWarning(params, relatedQuestion);
      }
      const questionIndex = this.selectedQuestions.findIndex(
        (el) => el.id === params.id,
      );
      this.selectedQuestions.splice(questionIndex, 1);
    },
    selectQuesitonGroup(value) {
      this.questionGroup = this.questionGroups.find((el) => el.value === value);
      this.selectedQuestions = [];
      this.getQuestionsById();
    },
    showPopupWarning(item, relatedQuestion) {
      this.$swal({
        title: 'Xác nhận',
        text: `Câu hỏi này là điều kiện hiển thị của câu hỏi ${relatedQuestion.name}`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (!result) {
            const question = this.selectedQuestions.find(
              (el) => el.id === item.id,
            );
            question.checked = true;
          }
        }.bind(this),
      );
    },
    saveQuestions() {
      const section = this.sections.find((el) => el.id === this.id);
      const questionsAddNew = this.getItemAdd(
        section.questions,
        this.selectedQuestions,
      );
      if (questionsAddNew.length) {
        this.selectedQuestions = [...section.questions, ...questionsAddNew].map(
          (el, i) => ({ ...el, order: i + 1 }),
        );
      }
      this.ADD_QUESTION_SURVEY({
        id: this.id,
        questions: this.selectedQuestions,
        isEdit: this.$route.name === 'survey_edit',
      });
    },
    getItemAdd(defaultItems, beforeSubmitItems) {
      let addItems = differenceBy(beforeSubmitItems, defaultItems, 'id');
      addItems = addItems.map((el) => {
        const params = { ...el };
        if (this.$route.name === 'survey_edit') {
          params['modelStatus'] = MODAL_STATUS.NEW;
        }
        return params;
      });
      return addItems;
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap-table {
  max-height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
